<app-header title="Statistiques du match" displayDescription=true gameMaj={{game.maj}} gameDate={{game.gameDate}} gameTime={{game.gameTime}}></app-header>
<div class="game">
  <div class="game-container">
    <a class="backLink" (click)="goBack()">< List des matches</a>
    <div class="timing">
      {{game.minutes}}' MIN
    </div>
      <div class="detail">
        <div class="detail-item first"><img class='teamlogo' src="../../assets/teams/{{game.localTeam}}.png"/> {{game.localTeam}}</div>
        <div class="detail-item center">J. {{game.gameNumber}}</div>
        <div class="detail-item end">{{game.awayTeam}} <img class='teamlogo' src="../../assets/teams/{{game.awayTeam}}.png"/></div>
      </div>
      <div class="detail">
        <div class="detail-item first">{{game.localGoals}}</div>
        <div class="detail-item center">BUTS</div>
        <div class="detail-item end">{{game.awayGoals}}</div>
      </div>
      <div class="detail">
        <div class="detail-item first">{{game.localPossession}}%</div>
        <div class="detail-item center">Possession de balle</div>
        <div class="detail-item end">{{game.awayPossession}}%</div>
      </div>
      <div class="detail">
        <div class="detail-item first">{{game.localTargetShoot}}</div>
        <div class="detail-item center">Tirs cadrés</div>
        <div class="detail-item end">{{game.awayTargetShoot}}</div>
      </div>
      <div class="detail">
        <div class="detail-item first">{{game.localNoTargetShoots}}</div>
        <div class="detail-item center">Tirs non cadrés</div>
        <div class="detail-item end">{{game.awayNoTargetShoots}}</div>
      </div>
      <div class="detail">
        <div class="detail-item first">{{game.localSaveKeeper}}</div>
        <div class="detail-item center">Arrets du gardien</div>
        <div class="detail-item end">{{game.awaySaveKeeper}}</div>
      </div>
      <div class="detail">
        <div class="detail-item first">{{game.localFaults}}</div>
        <div class="detail-item center">fautes</div>
        <div class="detail-item end">{{game.awayFaults}}</div>
      </div>
      <div class="detail">
        <div class="detail-item first">{{game.localCorners}}</div>
        <div class="detail-item center">cornes</div>
        <div class="detail-item end">{{game.awayCorners}}</div>
      </div>
      <div class="detail">
        <div class="detail-item first">{{game.localYellowCard}}</div>
        <div class="detail-item center">cartonsjaunes</div>
        <div class="detail-item end">{{game.awayYellowCard}}</div>
      </div>
      <div class="detail">
        <div class="detail-item first">{{game.localRedCard}}</div>
        <div class="detail-item center">cartons rouge</div>
        <div class="detail-item end">{{game.awayRedCard}}</div>
      </div>
      <div class="detail">
        <div class="detail-item first">{{game.localOffsite}}</div>
        <div class="detail-item center">Hors-jeux</div>
        <div class="detail-item end">{{game.awayOffsite}}</div>
      </div>
  </div>
</div>
<div class="footer">
Copyright @ Arryadia
</div>

