import { Injectable } from '@angular/core';
import { Game } from './game';
import GAMESDB from '../assets/games.json';
// import { GAMES } from './mock-games';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor() { }

  getGames(): Observable<Game[]> {
    return of(GAMESDB);
    // console.log(GAMESDB);
    // return null;
  }
  getGame(id: string): Observable<Game> {
    return of(GAMESDB.find(game => game.id === id));
    // return null;
  }
}
