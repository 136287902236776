<div class="header">
  <div class="header-container">
    <mat-grid-list cols="2" rowHeight="2:1">
      <mat-grid-tile>
        <div class="header-logo"><img src="../../assets/logo.png" alt="logo" /></div>
        <div class="header-title">{{title}}</div>
      </mat-grid-tile>
      <mat-grid-tile >
        <div class="header-description" *ngIf="displayDescription;">
            {{gameDate}} - {{gameTime}}<br/>
            MAJ: {{gameMaj}}

        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
