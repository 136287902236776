import { InvokeFunctionExpr } from '@angular/compiler';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() title: String;
  @Input() gameDate: String;
  @Input() gameTime: String;
  @Input() gameMaj: String;
  @Input() displayDescription: Boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
