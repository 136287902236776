
<div class="game">
  <div class="game-container" *ngFor="let game of games" [class.selected]="game === selectedGame" (click)="onSelect(game)">
    <div class="journee">J. {{game.gameNumber}}</div>
      <div class="detail">
        <div class="detail-item">{{game.gameDate}} {{game.gameTime}} </div>
        <div class="detail-item"><img class='teamlogo' src="../../assets/teams/{{game.localTeam}}.png"/> {{game.localTeam}} VS {{game.awayTeam}} <img class='teamlogo' src="../../assets/teams/{{game.awayTeam}}.png"/></div>
        <div class="detail-item">{{game.localGoals}} - {{game.awayGoals}} <a routerLink="/detail/{{game.id}}">Info</a></div>

      </div>
  </div>
</div>
